import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import * as path from 'path';
import { ModalAccountActivationComponent } from './modal-account-activation/modal-account-activation.component';
import { ModalActiveCardComponent } from './modal-active-card/modal-active-card.component';
import { ModalAlertBilletComponent } from './modal-alert-billet/modal-alert-billet.component';
import { ModalAlertCardComponent } from './modal-alert-card/modal-alert-card.component';
import { ModalAlertComponent } from './modal-alert/modal-alert.component';
import {ModalBilletJurosComponent} from './modal-billet-juros/modal-billet-juros.component';
import { ModalBilletReviewChargingComponent } from './modal-billet-review-charging/modal-billet-review-charging.component';
import { ModalBilletReviewComponent } from './modal-billet-review/modal-billet-review.component';
import { ModalCardDocumentComponent } from './modal-card-document/modal-card-document.component';
import { ModalCardNotificationComponent } from './modal-card-notification/modal-card-notification.component';
import { ModalDetailsDdaComponent } from './modal-details-dda/modal-details-dda.component';
import { ModalExpenseControlComponent } from './modal-expense-control/modal-expense-control.component';
import { ModalExportExtractComponent } from './modal-export-extract/modal-export-extract.component';
import { ModalExtractReviewComponent } from './modal-extract-review/modal-extract-review.component';
import { ModalOperationNotificationComponent } from './modal-operation-notification/modal-operation-notification.component';
import { ModalPrivacyPolicyComponent } from './modal-privacy-policy/modal-privacy-policy.component';
import { ModalProsperaAttendanceComponent } from './modal-prospera-attendance/modal-prospera-attendance.component';
import { ModalProsperaBilletComponent } from './modal-prospera-billet/modal-prospera-billet.component';
import { ModalProsperaNotificationComponent } from './modal-prospera-notification/modal-prospera-notification.component';
import { ModalSearchBankComponent } from './modal-search-bank/modal-search-bank.component';
import { ModalSharePage } from './modal-share/modal-share.page';
import { ModalTrackingProcessComponent } from './modal-tracking-process/modal-tracking-process.component';
import {
  ModalTransferNotificationAcceptedComponent,
} from './modal-transfer-notification-accepted/modal-transfer-notification-accepted.component';
import {
  ModalTransferNotificationCompletedComponent,
} from './modal-transfer-notification-completed/modal-transfer-notification-completed.component';
import {
  ModalTransferNotificationRefusedComponent,
} from './modal-transfer-notification-refused/modal-transfer-notification-refused.component';
import { ModalTransferResumeComponent } from './modal-transfer-resume/modal-transfer-resume.component';
import { ModalUnlockCardAlertComponent } from './modal-unlock-card-alert/modal-unlock-card-alert.component';
import { ModalUnlockCardComponent } from './modal-unlock-card/modal-unlock-card.component';
import { ModalValidationComponent } from './modal-validation/modal-validation.component';
import { PopoverFavoritesComponent } from './popover-favorites/popover-favorites.component';
import { ModalProsperaWalletComponent } from "./modal-prospera-wallet/modal-prospera-wallet.component";
import { ModalViewFileUpload } from './modal-view-file-upload/modal-view-file-upload.component';
import { ModalTokenChangePasswordComponent } from './modal-token-change-password/modal-token-change-password.component';
import { ModalAlertAccessWebMobileDeviceComponent } from './modal-alert-access-web-mobile-device/access-web-mobile-device.component';
import { ModalAlertDocsComponent } from './modal-alert-docs/modal-alert-docs.component';
import { ModalDetailDualAuth } from './modal-detail-dualauth/modal-detail-dualauth.component';
import { ModalBilletViewDetailComponent } from './modal-billet-view-detail/modal-billet-view-detail';
import { ModalBilletAlertCancelComponent } from './modal-billet-alert-cancel/modal-billet-alert-cancel';
import { ModalBilletEmit } from './modal-billet-emit/modal-billet-emit.component';
import { ModalBilletFavorites } from './modal-billet-favorites/modal-billet-favorites.component';
import { ModalBilletViewDetailV2Component } from './modal-billet-view-detail-v2/modal-billet-view-detail-v2.component';
import { ModalAlertBilletTaxesComponent } from './modal-alert-billet-taxes/modal-alert-billet-taxes.component';
import { ModalCnabShippingComponent } from './modal-cnab-shipping/modal-cnab-shipping.component';
import { ModalCnabShippingLineComponent } from './modal-cnab-shipping-line/modal-cnab-shipping-line.component';
import { ModalSelfPhotoComponent } from './modal-self-photo/modal-self-photo.component';

const routes: Routes = [
  {
    path: 'modal-billet-emit',
    component: ModalBilletEmit
  },
  {
    path: 'modal-billet-favorites',
    component: ModalBilletFavorites
  },
  {
    path: 'modal-billet-alert-cancel',
    component: ModalBilletAlertCancelComponent,
  },
  {
    path: 'modal-billet-view-detail',
    component: ModalBilletViewDetailComponent,
  },
  {
    path: 'modal',
    component: ModalAlertComponent,
  },
  {
    path: 'modal-share',
    component: ModalSharePage,
  },
  {
    path: 'modal-billet-review',
    component: ModalBilletReviewComponent,
  },
  {
    path: 'modal-transfer-notification-completed',
    component: ModalTransferNotificationCompletedComponent,
  },
  {
    path: 'modal-transfer-notification-accepted',
    component: ModalTransferNotificationAcceptedComponent,
  },
  {
    path: 'modal-transfer-notification-refused',
    component: ModalTransferNotificationRefusedComponent,
  },
  {
    path: 'modal-transfer-resume',
    component: ModalTransferResumeComponent,
  },
  {
    path: 'modal-operation-notification',
    component: ModalOperationNotificationComponent,
  },
  {
    path: 'modal-search-bank',
    component: ModalSearchBankComponent,
  },
  {
    path: 'modal-extract-review',
    component: ModalExtractReviewComponent,
  },
  {
    path: 'modal-prospera-notification',
    component: ModalProsperaNotificationComponent,
  },
  {
    path: 'modal-prospera-attendance',
    component: ModalProsperaAttendanceComponent,
  },
  {
    path: 'modal-prospera-billet',
    component: ModalProsperaBilletComponent,
  },
  {
    path: 'modal-prospera-wallet',
    component: ModalProsperaWalletComponent,
  },
  {
    path: 'modal-card-document',
    component: ModalCardDocumentComponent,
  },
  {
    path: 'modal-card-notification',
    component: ModalCardNotificationComponent,
  },
  {
    path: 'modal-active-card',
    component: ModalActiveCardComponent,
  },
  {
    path: 'modal-alert-card',
    component: ModalAlertCardComponent,
  },
  {
    path: 'modal-unlock-card',
    component: ModalUnlockCardComponent,
  },
  {
    path: 'modal-unlock-card-alert',
    component: ModalUnlockCardAlertComponent,
  },
  {
    path: 'modal-billet-review-charging',
    component: ModalBilletReviewChargingComponent,
  },
  {
    path: 'modal-alert-billet',
    component: ModalAlertBilletComponent,
  },
  {
    path: 'modal-account-activation',
    component: ModalAccountActivationComponent,
  },
  {
    path: 'popover-favorites',
    component: PopoverFavoritesComponent,
  },
  {
    path: 'modal-expense-control',
    component: ModalExpenseControlComponent,
  },
  {
    path: 'modal-validation',
    component: ModalValidationComponent,
  },
  {
    path: 'modal-privacy-policy',
    component: ModalPrivacyPolicyComponent,
  },
  {
    path: 'modal-details-dda',
    component: ModalDetailsDdaComponent,
  },
  {
    path: 'modal-view-file-upload',
    component: ModalViewFileUpload
  },
  {
    path: 'modal-token-change-password',
    component: ModalTokenChangePasswordComponent,
  },
  {
    path: "access-web-mobile-device",
    component: ModalAlertAccessWebMobileDeviceComponent,
  },
  {
    path: 'modal-alert-docs',
    component: ModalAlertDocsComponent,
  },
  {
    path: 'modal-detail-dualauth',
    component: ModalDetailDualAuth,
  },
  {
    path: 'modal-export-extract',
        component: ModalExportExtractComponent,
  },
  {
    path: 'modal-billet-juros',
    component: ModalBilletJurosComponent,
  },
  {
    path: 'modal-billet-view-detail-v2',
    component: ModalBilletViewDetailV2Component
  },
  {    
    path: 'modal-billet-juros',
    component: ModalBilletJurosComponent,
  },
  {
    path: 'modal-alert-billet-taxes',
    component: ModalAlertBilletTaxesComponent
  },
  {
    path: 'modal-billet-view-detail-v2',
    component: ModalBilletViewDetailV2Component,
  },
  {
    path: 'modal-tracking-process',
    component: ModalTrackingProcessComponent,
  },
  {
    path: 'modal-cnab-shipping',
    component: ModalCnabShippingComponent
  },
  {
    path: "modal-cnab-shipping-line",
    component: ModalCnabShippingLineComponent
  },
  {
    path: 'modal-self-photo',
    component: ModalSelfPhotoComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModalsRoutingModule { }
